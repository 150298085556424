/**
 * Reset some basic elements
 */
 @import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
 
 body, h1, h2, h3, h4, h5, h6,
 p, blockquote, pre, hr,
 dl, dd, ol, ul, figure {
   margin: 0;
   padding: 0;
 }
 
 /**
  * Basic styling
  */
 body {
   font-family: 'Roboto', serif;
   font-weight: 400;
   line-height: 1.60em;
   color: #ECDFCC;
   background-color: #181C14;
   -webkit-text-size-adjust: 100%;
   -webkit-font-feature-settings: "kern" 1;
   -moz-font-feature-settings: "kern" 1;
   -o-font-feature-settings: "kern" 1;
   font-feature-settings: "kern" 1;
   font-kerning: normal;
   display: flex;
   min-height: 100vh;
   flex-direction: column;
 }
 
 /**
  * Set `margin-bottom` to maintain vertical rhythm
  */
 h1, h2, h3, h4, h5, h6,
 p, blockquote, pre,
 ul, ol, dl, figure,
 .highlight {
   margin-bottom: 15px;
 }
 
 /**
  * `main` element
  */
 main {
   display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
 }
 
 /**
  * Images
  */
 img {
   max-width: 100%;
   vertical-align: middle;
 }
 
 /**
  * Figures
  */
 figure > img {
   display: block;
 }
 
 figcaption {
   font-size: 14px;
 }
 
 /**
  * Lists
  */
 ul, ol {
   margin-left: 0;
 }
 
 li > ul,
 li > ol {
   margin-bottom: 0;
 }
 
 /**
  * Headings
  */
 h1, h2, h3, h4, h5, h6 {
   font-weight: 400;
 }
 
 /**
  * Links
  */
 a {
   color: #73BBA3;
   text-decoration: none;
 }
 a:visited {
   color: #73BBA3;
  }
 a:hover {
   color: #697565;   
   text-decoration: underline;
 }
 .social-media-list a:hover {
   text-decoration: none;
 }
 .social-media-list a:hover .username {
   text-decoration: underline;
 }
 
 /**
  * Blockquotes
  */
 blockquote {
   color: #828282;
   border-left: 4px solid #e8e8e8;
   padding-left: 14px;
   font-size: 16px;
   letter-spacing: -1px;
 }
 blockquote > :last-child {
   margin-bottom: 0;
 }
 
 /**
  * Code formatting
  */
 pre,
 code {
   font-family: "Fira Code", serif;
   font-size: 13px;
   border: 1px solid #222;
   border-radius: 3px;
   color: #ECDFCC;
   background-color: #111;
 }
 
 code {
   padding: 1px 5px;
 }
 
 pre {
   padding: 8px 12px;
   overflow-x: auto;
 }
 pre > code {
   font-family: "Fira Code", serif;
   border: 0;
   padding-right: 0;
   padding-left: 0;
 }
 
 .video-container {
   position: relative;
   padding-bottom: 56.25%;
 }
 
 .video-container iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 
 .pager ul.pagination li {
     font-family: "Arial", sans-serif;
     float: left;
     list-style-type: none;
     padding: 0 0 0 0;
     background-color: #f0f0f0;
 }

.pagination {
  font-family: "Arial", sans-serif;
  font-size:14px;
}
 
 #search-container input {
   width: 40%;
   padding:10px;
   margin:0px;
   color: #ECDFCC;
   background-color: #181C14;
   border: 1px solid #333;
 }
 
 ul#results-container {
   margin-top:20px;
 }
 
 ul#results-container li {
   margin:0;
   padding:0;
   margin-top:-5px;
   list-style-type: none;
 }
 
 .date {
   #font-family: 'Fira Code', serif;
   color: #999;
   font-size: 14px;
 }
 
 .highlight code {
   #font-family: 'Fira Code', serif;
   font-weight: 400;
   font-size: 13px;
   line-height: 0.5em;
 }
 
 strong {
   color: #73BBA3;
   font-style: bold;
 }
 
 /**
  * Wrapper
  */
 .wrapper {
   font-size:16px;
   max-width: -webkit-calc(1280px - (30px * 2));
   max-width: calc(1280px - (30px * 2));
   margin-right: auto;
   margin-left: auto;
   padding-right: 30px;
   padding-left: 30px;
 }
 @media screen and (max-width: 1200px) {
   .wrapper {
     max-width: -webkit-calc(1280px - (30px));
     max-width: calc(1280px - (30px));
     padding-right: 15px;
     padding-left: 15px;
   }
 }
 
 /**
  * Clearfix
  */
 .footer-col-wrapper:after, .wrapper:after {
   content: "";
   display: table;
   clear: both;
 }
 
 /**
  * Icons
  */
 .svg-icon {
   width: 16px;
   height: 16px;
   display: inline-block;
   fill: #828282;
   padding-right: 5px;
   vertical-align: text-top;
 }
 
 .social-media-list li + li {
   padding-top: 5px;
 }
 
 /**
  * Tables
  */
 table {
   margin-bottom: 30px;
   width: 100%;
   text-align: left;
   color: #3f3f3f;
   border-collapse: collapse;
   border: 1px solid #222;
 }
 table tr:nth-child(even) {
   background-color: #ECDFCC;
 }
 table th, table td {
   padding: 10px 15px;
 }
 table th {
   background-color: #f0f0f0;
   border: 1px solid #dedede;
   border-bottom-color: #c9c9c9;
 }
 table td {
   border: 1px solid #e8e8e8;
 }
 
 /**
  * Site header
  */
 .site-header {
   border-top: 5px solid #424242;
   border-bottom: 1px solid #111;
   min-height: 55.95px;
   position: relative;
 }
 
 .site-title {
   font-size: 24px;
   font-weight: 300;
   line-height: 64px;
   letter-spacing: -1px;
   margin-bottom: 0;
   float: left;
 }
 .site-title, .site-title:visited {
   color: #ECDFCC;
 }

.site-title:hover {
  color: #697565;
}

 .site-nav {
   float: right;
   line-height: 54px;
 }
 .site-nav .nav-trigger {
   display: none;
 }
 .site-nav .menu-icon {
   display: none;
 }
 .site-nav .page-link {
   color: #ECDFCC;
   line-height: 1.5;
 }
 .site-nav:hover .page-link:hover {
  color: #697565;
  line-height: 1.5;
} 
 .site-nav .page-link:not(:last-child) {
   margin-right: 20px;
 }
 @media screen and (max-width: 600px) {
   .site-nav {
     position: absolute;
     top: 9px;
     right: 15px;
     background-color: #fdfdfd;
     border: 1px solid #e8e8e8;
     border-radius: 5px;
     text-align: right;
   }
   .site-nav label[for=nav-trigger] {
     display: block;
     float: right;
     width: 36px;
     height: 36px;
     z-index: 2;
     cursor: pointer;
   }
   .site-nav .menu-icon {
     display: block;
     float: right;
     width: 36px;
     height: 26px;
     line-height: 0;
     padding-top: 10px;
     text-align: center;
   }
   .site-nav .menu-icon > svg {
     fill: #424242;
   }
   .site-nav input ~ .trigger {
     clear: both;
     display: none;
   }
   .site-nav input:checked ~ .trigger {
     display: block;
     padding-bottom: 5px;
   }
   .site-nav .page-link {
     display: block;
     padding: 5px 10px;
     margin-left: 20px;
   }
   .site-nav .page-link:not(:last-child) {
     margin-right: 0;
   }
 }
 
 /**
  * Site footer
  */
 .site-footer {
   border-top: 1px solid #111;
   padding: 30px 0;
 }
 
 .footer-heading {
   font-size: 18px;
   margin-bottom: 15px;
 }
 
 .contact-list,
 .social-media-list {
   list-style: none;
   margin-left: 0;
 }
 
 .footer-col-wrapper {
   font-size: 15px;
   color: #ECDFCC;
   margin-left: -15px;
 }
 
 .footer-col-right {
   float: right;
   margin-bottom: 15px;
   padding-left: 15px;
 }
 
 .footer-col {
   float: left;
   margin-bottom: 15px;
   padding-left: 15px;
 }
 
 .footer-col-1 {
   width: -webkit-calc(35% - (30px / 2));
   width: calc(35% - (30px / 2));
 }
 
 .footer-col-2 {
   width: -webkit-calc(20% - (30px / 2));
   width: calc(20% - (30px / 2));
 }
 
 .footer-col-3 {
   width: -webkit-calc(45% - (30px / 2));
   width: calc(45% - (30px / 2));
 }
 
 @media screen and (max-width: 800px) {
   .footer-col-1,
   .footer-col-2 {
     width: -webkit-calc(50% - (30px / 2));
     width: calc(50% - (30px / 2));
   }
   .footer-col-3 {
     width: -webkit-calc(100% - (30px / 2));
     width: calc(100% - (30px / 2));
   }
 }
 @media screen and (max-width: 600px) {

   .numbers {
     display: none;
   }
   .footer-col-right {
     float: none;
     margin-bottom: 15px;
     padding-left: 15px;
   }
   .footer-col {
     float: none;
     width: -webkit-calc(100% - (30px / 2));
     width: calc(100% - (30px / 2));
   }
 }
 /**
  * Page content
  */
 .page-content {
   padding: 30px 0;
   flex: 1;
 }

 .page-content h1 {
  font-size: 24px
}

 .page-content h4 {
   line-height: 1.2em;
   padding: 0;
   margin: 1px 0;
 }

 .page-content h4 a {
   font-size:16px;
 }
 
 .page-heading {
   font-size: 32px;
 }
 
 .post-list-heading {
   font-size: 28px;
 }
 
 .post-list {
   margin-left: 0;
   list-style: none;
 }
 .post-list > li {
   margin-bottom: 30px;
 }
 
 .post-meta {
   font-size: 14px;
   color: #828282;
 }
 
 .post-link {
   display: block;
   font-size: 24px;
 }
 
 /**
  * Posts
  */
 .post-header {
   margin-bottom: 30px;
 }
 
 .post-title {
   font-size: 18px;
   letter-spacing: -1px;
   line-height: 1;
 }
 @media screen and (max-width: 800px) {
   .post-title {
     font-size: 36px;
   }
 }
 
 .post-content {
   margin-bottom: 30px;
 }
 .post-content h2 {
   font-size: 32px;
 }
 @media screen and (max-width: 800px) {
   .post-content h2 {
     font-size: 28px;
   }
 }
 .post-content h3 {
   font-size: 26px;
 }
 @media screen and (max-width: 800px) {
   .post-content h3 {
     font-size: 22px;
   }
 }
 .post-content h4 {
   font-size: 20px;
 }
 @media screen and (max-width: 800px) {
   .post-content h4 {
     font-size: 18px;
   }
 }
 
 /**
  * Syntax highlighting styles
  */
 .highlight {
   background: #111;
 }
 .highlighter-rouge .highlight {
   background: #111;
 }
 .highlight .c {
   color: #998;
   font-style: italic;
 }
 .highlight .err {
   color: #a61717;
   background-color: #e3d2d2;
 }
 .highlight .k {
   font-weight: bold;
 }
 .highlight .o {
   font-weight: bold;
 }
 .highlight .cm {
   color: #998;
   font-style: italic;
 }
 .highlight .cp {
   color: #999;
   font-weight: bold;
 }
 .highlight .c1 {
   color: #998;
   font-style: italic;
 }
 .highlight .cs {
   color: #999;
   font-weight: bold;
   font-style: italic;
 }
 .highlight .gd {
   color: #000;
   background-color: #fdd;
 }
 .highlight .gd .x {
   color: #000;
   background-color: #faa;
 }
 .highlight .ge {
   font-style: italic;
 }
 .highlight .gr {
   color: #a00;
 }
 .highlight .gh {
   color: #999;
 }
 .highlight .gi {
   color: #000;
   background-color: #dfd;
 }
 .highlight .gi .x {
   color: #000;
   background-color: #afa;
 }
 .highlight .go {
   color: #888;
 }
 .highlight .gp {
   color: #555;
 }
 .highlight .gs {
   font-weight: bold;
 }
 .highlight .gu {
   color: #aaa;
 }
 .highlight .gt {
   color: #a00;
 }
 .highlight .kc {
   font-weight: bold;
 }
 .highlight .kd {
   font-weight: bold;
 }
 .highlight .kp {
   font-weight: bold;
 }
 .highlight .kr {
   font-weight: bold;
 }
 .highlight .kt {
   color: #458;
   font-weight: bold;
 }
 .highlight .m {
   color: #099;
 }
 .highlight .s {
   color: #d14;
 }
 .highlight .na {
   color: #008080;
 }
 .highlight .nb {
   color: #0086B3;
 }
 .highlight .nc {
   color: #458;
   font-weight: bold;
 }
 .highlight .no {
   color: #008080;
 }
 .highlight .ni {
   color: #800080;
 }
 .highlight .ne {
   color: #900;
   font-weight: bold;
 }
 .highlight .nf {
   color: #900;
   font-weight: bold;
 }
 .highlight .nn {
   color: #555;
 }
 .highlight .nt {
   color: #000080;
 }
 .highlight .nv {
   color: #008080;
 }
 .highlight .ow {
   font-weight: bold;
 }
 .highlight .w {
   color: #bbb;
 }
 .highlight .mf {
   color: #099;
 }
 .highlight .mh {
   color: #099;
 }
 .highlight .mi {
   color: #099;
 }
 .highlight .mo {
   color: #099;
 }
 .highlight .sb {
   color: #d14;
 }
 .highlight .sc {
   color: #d14;
 }
 .highlight .sd {
   color: #d14;
 }
 .highlight .s2 {
   color: #d14;
 }
 .highlight .se {
   color: #d14;
 }
 .highlight .sh {
   color: #d14;
 }
 .highlight .si {
   color: #d14;
 }
 .highlight .sx {
   color: #d14;
 }
 .highlight .sr {
   color: #009926;
 }
 .highlight .s1 {
   color: #d14;
 }
 .highlight .ss {
   color: #990073;
 }
 .highlight .bp {
   color: #999;
 }
 .highlight .vc {
   color: #008080;
 }
 .highlight .vg {
   color: #008080;
 }
 .highlight .vi {
   color: #008080;
 }
 .highlight .il {
   color: #099;
 }
 
 /*# sourceMappingURL=main.css.map */
